const sentinoExample = {
  text: "I am 30 years old man.  I studied physics and currently work as a software engineer. I am married and have a couple of kids.  What else I can tell... I am open to new experience and like to chat. I am being nasty sometimes.",
  inventories: ["big5", "neo"],
  scoring: {
    big5: {
      agreeableness: {
        quantile: 0.416,
        score: 0.547,
        confidence: 0.007,
        confidence_text: "very low",
      },
      conscientiousness: {
        quantile: 0.439,
        score: 0.37,
        confidence: 0.007,
        confidence_text: "very low",
      },
      extraversion: {
        quantile: 0.454,
        score: 0.267,
        confidence: 0.007,
        confidence_text: "very low",
      },
      neuroticism: {
        quantile: 0.546,
        score: -0.267,
        confidence: 0.007,
        confidence_text: "very low",
      },
      openness: {
        quantile: 1,
        score: 1,
        confidence: 0.134,
        confidence_text: "low",
      },
    },
    neo: {
      achievement: {
        quantile: 0.423,
        score: 0.488,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "activity level": {
        quantile: 0.491,
        score: 0.048,
        confidence: 0.007,
        confidence_text: "very low",
      },
      adventurousness: {
        quantile: 1,
        score: 1,
        confidence: 0.186,
        confidence_text: "low",
      },
      altruism: {
        quantile: 0.415,
        score: 0.556,
        confidence: 0.007,
        confidence_text: "very low",
      },
      anger: {
        quantile: 0.55,
        score: -0.295,
        confidence: 0.007,
        confidence_text: "very low",
      },
      anxiety: {
        quantile: 0.536,
        score: -0.207,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "artistic interests": {
        quantile: 0.418,
        score: 0.527,
        confidence: 0.007,
        confidence_text: "very low",
      },
      assertiveness: {
        quantile: 0.474,
        score: 0.143,
        confidence: 0.007,
        confidence_text: "very low",
      },
      cautiousness: {
        quantile: 0.449,
        score: 0.304,
        confidence: 0.007,
        confidence_text: "very low",
      },
      cheerfulness: {
        quantile: 0.444,
        score: 0.333,
        confidence: 0.007,
        confidence_text: "very low",
      },
      cooperation: {
        quantile: 0,
        score: -1,
        confidence: 0.172,
        confidence_text: "low",
      },
      depression: {
        quantile: 0.568,
        score: -0.424,
        confidence: 0.007,
        confidence_text: "very low",
      },
      dutifulness: {
        quantile: 0.397,
        score: 0.709,
        confidence: 0.007,
        confidence_text: "very low",
      },
      emotionality: {
        quantile: 0.449,
        score: 0.302,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "excitement-seeking": {
        quantile: 0.536,
        score: -0.203,
        confidence: 0.007,
        confidence_text: "very low",
      },
      friendliness: {
        quantile: 0.44,
        score: 0.366,
        confidence: 0.007,
        confidence_text: "very low",
      },
      gregariousness: {
        quantile: 0.501,
        score: -0.007,
        confidence: 0.007,
        confidence_text: "very low",
      },
      imagination: {
        quantile: 0.466,
        score: 0.193,
        confidence: 0.007,
        confidence_text: "very low",
      },
      immoderation: {
        quantile: 0.521,
        score: -0.112,
        confidence: 0.007,
        confidence_text: "very low",
      },
      intellect: {
        quantile: 0.447,
        score: 0.314,
        confidence: 0.007,
        confidence_text: "very low",
      },
      liberalism: {
        quantile: 0.535,
        score: -0.196,
        confidence: 0.007,
        confidence_text: "very low",
      },
      modesty: {
        quantile: 0.48,
        score: 0.111,
        confidence: 0.007,
        confidence_text: "very low",
      },
      morality: {
        quantile: 0,
        score: -1,
        confidence: 0.159,
        confidence_text: "low",
      },
      orderliness: {
        quantile: 0.441,
        score: 0.356,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "self-consciousness": {
        quantile: 0.534,
        score: -0.193,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "self-discipline": {
        quantile: 0.448,
        score: 0.308,
        confidence: 0.007,
        confidence_text: "very low",
      },
      "self-efficacy": {
        quantile: 0.423,
        score: 0.491,
        confidence: 0.007,
        confidence_text: "very low",
      },
      sympathy: {
        quantile: 0.45,
        score: 0.299,
        confidence: 0.007,
        confidence_text: "very low",
      },
      trust: {
        quantile: 0.44,
        score: 0.362,
        confidence: 0.007,
        confidence_text: "very low",
      },
      vulnerability: {
        quantile: 0.565,
        score: -0.399,
        confidence: 0.007,
        confidence_text: "very low",
      },
    },
  },
  lang: "en",
};

export default sentinoExample;

const cartItems = [
  {
    id: "1",
    title: "Nutifröggen",
    price: "19.99",
    amount: "1",
  },
  {
    id: "2",
    title: "Nutifröggen2",
    price: "29.99",
    amount: "1",
  },
  {
    id: "3",
    title: "Nutifröggen3",
    price: "39.9",
    amount: "1",
  },
  {
    id: "4",
    title: "Nutifröggen4",
    price: "49.9",
    amount: "1",
  },
];

export default cartItems;
